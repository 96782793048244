var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"School","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"School","label-for":"school","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"selectable":function (val) { return val.can_select; },"reduce":function (val) { return val.value; },"clearable":false,"input-id":"school"},model:{value:(_vm.userData.school),callback:function ($$v) {_vm.$set(_vm.userData, "school", $$v)},expression:"userData.school"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.submitChanges}},[_vm._v(" "+_vm._s(_vm.$t('forms.save_changes'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){_vm.hasHistory() 
      ? _vm.$router.go(-1) 
      : _vm.$router.push({ 
      name: 'school-teachers-view', 
      params: {
                  id: _vm.school.id,
                  teacher_id: _vm.userData.id,
                },
    })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }