<template>
  <div>
    <custom-breadcrumb
      v-if="userData"
      :pageTitle="$t('forms.edit_model', {model: $t('models.teacher')})"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    <component :is="userData === undefined ? 'div' : 'b-card'">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching user data
        </h4>
        <div class="alert-body">
          No user found with this user id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>

      <b-tabs v-if="userData">
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <tab-account
            :school="school"
            :user-data="userData"
            :roleOptions="roleOptions"
            :langsOptions="langsOptions"
            @refetch-data="getData(userData.id)"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Password -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Security</span>
          </template>
          <tab-security
            :school="school"
            :user-data="userData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Subscriptions -->
        <b-tab v-if="original_user_role_id && original_user_role_id != 3">
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Subscriptions</span>
          </template>
          <tab-subscriptions
            :user-data="userData"
            :school="school"
            :blocked_payment_methods="blocked_payment_methods"
            :planOptions="planOptions"
            class="mt-2 pt-75"
            @refetch-data="getData(userData.id)"
          />
        </b-tab>

        <!-- Tab: School -->
        <b-tab v-if="original_user_role_id && original_user_role_id != 3">
          <template #title>
            <feather-icon
              icon="BriefcaseIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">School</span>
          </template>
          <tab-school
            :school="school"
            :user-data="userData"
            :schoolOptions="schoolOptions"
            :roleOptions="schoolOptions"
            :langsOptions="langsOptions"
            @refetch-data="getData(userData.id)"
            class="mt-2 pt-75"
          />
        </b-tab>
      </b-tabs>
    </component>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import TabSchool from './edit/TabSchool.vue'
import TabAccount from './edit/TabAccount.vue'
import TabSecurity from './edit/TabSecurity.vue'
import TabSubscriptions from './edit/TabSubscriptions.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    TabAccount,
    TabSecurity,
    TabSubscriptions,
    TabSchool,
    CustomBreadcrumb
  },
  data(){
    return {
      id: null,
      userData: null,
      permissionData: null,
      roleOptions: [],
      schoolOptions: [],
      planOptions: [],
      langsOptions: [],
      original_user_role_id: null,
      blocked_payment_methods: [],

      breadcrumb: [],
      school:{}
    }
  },
  mounted(){
    this.id = router.currentRoute.params.teacher_id;
    this.getData(this.id)
  },
  methods: {
    async getData(id){
        console.log("loading")
        let data = {
          'id': id,
          'context': 'teacher'
        }
        await this.$http.post('fetchLicence', data).then( response =>{
            console.log(response)
            if(response.data.status == 200) {
              let roles = response.data.roles;
              let plans = response.data.plans;
              let langs = response.data.langs;
              let schools = response.data.schools;
              this.school = response.data.school;
              this.breadcrumb = [
                {
                  text: 'Schools',
                  to: 'schools'
                },
                {
                  to: 'school-view',
                  params: {
                    id: response.data.school.id,
                  },
                  text: response.data.school.name
                },
                {
                  to: 'school-teachers-list',
                  text: 'Teachers List'
                },
                {
                  to: 'school-teachers-view',
                  params: {
                    id: response.data.school.id,
                    teacher_id: response.data.user.id,
                  },
                  text: response.data.user.name ? response.data.user.name : 'Teacher '+response.data.user.id
                },
                {
                  text: 'View',
                  active: true,
                },
              ]

              //ROLES
              let newRolesOptions = []
              roles.forEach(rol => {
                let capName = rol.name.charAt(0).toUpperCase() + rol.name.slice(1);
                let can_select = true;
  
                if(rol.id == 3){
                  can_select = false;
                }
  
                newRolesOptions.push(
                    { 'value': rol.id, 'label': capName, 'can_select': can_select }
                ) 
              
              });
              this.roleOptions = newRolesOptions;
    
              //School
              let newSchoolOptions = []
              schools.forEach(x => {
                let can_select = true;
                if(x.canEdit==0){
                  can_select = false;
                }
                newSchoolOptions.push(
                    { 'value': x.id, 'label': x.name, 'can_select': can_select }
                ) 
              });
              this.schoolOptions = newSchoolOptions;

              //PLANS
              let newPlansOptions = []
              plans.forEach(x => {
                let can_select = true;
                if(x.canEdit==0){
                  can_select = false;
                }
                newPlansOptions.push(
                    { 'value': x.id, 'label': x.description, 'can_select': can_select }
                ) 
              });
              this.planOptions = newPlansOptions;
  
              //LANGS
              let newLangsOptions = []
              langs.forEach(x => {
              let capName = x.name.charAt(0).toUpperCase() + x.name.slice(1);
              newLangsOptions.push(
                  { 'value': x.id, 'label': capName }
              ) 
              });
              this.langsOptions = newLangsOptions;
  
              //USER DATA
              this.userData = response.data.user;
              this.original_user_role_id = response.data.user.role_id;
              this.permissionData = response.data.permissionData;
              this.blocked_payment_methods = response.data.blocked_payment_methods;
            }
            else{
              this.userData = undefined;
            }
            //this.setData(response);
        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  }
}
</script>

<style>

</style>
