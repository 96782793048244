<template>
  <div>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Full Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Full Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="John Doe"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <!-- Username / Email -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Username"
              rules="required"
            >
              <b-form-group
                label="Email / Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: max_children -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
            #default="validationContext"
            name="Max Children"
            rules="required|integer"
          >
            <b-form-group
              label="Max Children"
              label-for="max_children"
            >
              <b-form-input
                id="max_children"
                type="number"
                min="0"
                v-model="userData.max_children"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>

          <!-- Field: Language -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="Language"
                label-for="lang"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.lang"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="langsOptions"
                  :reduce="val => val.label"
                  :clearable="false"
                  input-id="lang"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="User Role"
              rules="required"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.role_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :selectable="val => val.can_select"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ 
          name: 'school-teachers-view', 
          params: {
                      id: school.id,
                      teacher_id: userData.id,
                    },
        })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },

    langsOptions: {
      type: Array,
      required: true,
    },
  },
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,

    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    async submitChanges(){
      console.log(this.userData)
      let data = {
          'tab': 'account',
          'user_id': this.userData.id,
          'name':this.userData.name,
          'email':this.userData.email,
          'max_children':this.userData.max_children,
          'lang':this.userData.lang,
          'role_id':this.userData.role_id
        }
      await this.$http.post('editLicence', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
